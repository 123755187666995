
"use client"
import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { format } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { cn } from "@/lib/utils"
import { Calendar } from "@/components/ui/calendar"
import moment from "moment";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { getIpsAddress } from "@/lib/getIP"
import { useState } from "react"
import { Icons } from "../ui/icons"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
// import PhoneInput from 'react-phone-number-input'
// import PhoneInputWithCountrySelect from "react-phone-number-input"
import Image from "next/image"

import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input"

export default function DownloadBrochurePopup(props: any) {
    const [loading, setLoading] = useState(false)
    const [closeCalader, setCloseCalader] = useState(false)

    const formSchema = z.object({
        username: z.string({ required_error: 'First name is required' })
        .min(3, { message: 'First name must be at least 3 characters' })
        .max(30, { message: 'First name cannot contain more than 30 characters' })
        .refine(
            (value) => /^[^\s][a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/.test(value),
            { message: 'Only alphabets allowed' }
        ),
        // last_name: z.string({ required_error: 'Last name is required' })
        // .min(1, { message: 'Last name must be at least 1 characters' })
        // .max(30, { message: 'Last name cannot contain more than 30 characters' })
        // .refine(
        //     (value) => /^[^\s][a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/.test(value),
        //     { message: 'Only alphabets allowed' }
        // ),
        email: z.string({ required_error: "Email is required" }).min(1, { message: "Email is required" }).regex(/^[A-Za-z0-9@.]+$/, { message: "Invalid email" }).email("Invalid email"),
        // dob: z.date({
        //     required_error: "A date of birth is required.",
        // }),
        // experience: z.string({ required_error: "This field is required.", }).min(1, {
        //     message: "Experience must be at least 1 Digit long.",
        // }).max(4, {
        //     message: "Experience must be 2 Digit long.",
        // }).refine((value) => /^[\d.]+$/.test(value), {
        //     message: "Experience can only contain numbers.",
        // }),
        // country: z.string().refine(
        //     (value) => /^[^\s][a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/.test(value),
        //     { message: 'Only alphabets allowed' }
        // ),
        // city: z.string().refine(
        //     (value) => /^[^\s][a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/.test(value),
        //     { message: 'Only alphabets allowed' }
        // ),
        phone: z.string({ required_error: "Phone number required", })
        .min(13, { message: 'Phone Number should contain 10 digits' })
        .max(15, { message: 'Phone Number should contain 10 digits' }).refine((value) => {
            const zeroCount = (value.match(/0/g) || []).length; 
            return zeroCount <= 7; 
          }, {
            message: 'Invalid Phone number',
          }),
          preferences: z.string({ required_error: "Please select country", }).min(1, "Country is required"),
    })

    const [value, setValue] = useState<string>('');
    // const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     // Update the value state when the input changes
    //     setValue(event.target.value);
    // };

    const form = useForm<z.infer<typeof formSchema>>({
        mode:"onChange",
        resolver: zodResolver(formSchema),
        // defaultValues: {
        //     username: "",
        //     last_name: "",
        //     email: "",
        //     phone: "",
        //     city: "",
        //     country: "",
        //     experience: ""
        // },
    })

    const downloadPDF = (url: any, filename: any) => {
        setLoading(true);
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                setLoading(false);
                const objectURL = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = objectURL;
                link.target = "_blank";
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => console.error("Error downloading PDF:", error));
    };

    const onSubmit = async (data: any) => {
        setLoading(true);
        const ips = await getIpsAddress()
        let datas = JSON.stringify({
            "firstName": data?.username,
            // "lastName": data?.last_name,
            "email": data?.email,
            // "phone": "+91" + data?.phone,
            "phone":data?.phone,
            // "dob": moment(data?.dob).format('DD-MM-YYYY'),
            // "city": data?.city,
            // "country": data?.country,
            "locationAppliedFrom": ips,
            // "experience": data?.experience,
            "sourceFrom": `${window?.location.pathname == "/" ? "Home" : window?.location.pathname} - Course Details`,
            "otherInfo": data
        });
        const result = await fetch('/api/master/marketing', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(datas)
        })
        setLoading(false)
        if (result?.status === 200 || result?.status === 201) {
            form.reset({
                username: "",
                // last_name: "",
                email: "",
                preferences: "",
                phone: "",
                // city: "",
                // country: "",
                // experience: ""
            });
            downloadPDF("https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/campaign/pdf/exc_academy_file.pdf", "course-brochure.pdf");

        }
    }

    const handleReset = ()=>{
        form.reset({
            username: "",
            // last_name: "",
            email: "",
            phone: "",
            // city: "",
            preferences: "",
            // country: "",
            // experience: ""
        })
        form.clearErrors();
    }
    
    return (
        <Dialog onOpenChange={(open) => {
            if (!open) handleReset();
        }}>
            <DialogTrigger asChild>
                <Button variant="primary" className="bg-white uppercase hover:bg-[#121212] hover:text-[#FFFFFF] text-[#121212] font-semibold text-[0.9rem] lg:text-lg md:text-base px-3 sm:px-3 py-3 sm:py-3 border-solid border-2 border-[#121212]  rounded-none inline-block no-arrow h-[60px]">Download Brochure</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px] md:max-w-[829px] p-0 overflow-hidden rounded-none max-h-screen">
                <DialogHeader className="text-center px-6 py-5 bg-[url('https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/d-banner.webp')] xl:min-h-[90px] lg:min-h-[90px] relative">
                    <DialogTitle className="font-semibold text-[20px] md:text-[28px] text-[#2D2C2C] text-center">
                        <Image height={92} width={96} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/d-icon.webp" alt="exa-acadamy-img" className="mx-auto xl:h-[92px] mb-3" />Download Brochure</DialogTitle>
                </DialogHeader>
                <div className="px-0">
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <div className="grid grid-cols-2 gap-4 mb-5 px-6">
                                <div className="col-span-2">
                                    <FormField
                                        control={form.control}
                                        name="username"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel className="text-black">Your Name<span className="text-destructive">*</span></FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Enter your name" className="rounded-none h-9" value={field.value} onChange={field.onChange} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                {/*<div className="sm:col-span-1 col-span-2">*/}
                                    {/*<FormField*/}
                                        {/*control={form.control}*/}
                                        {/*name="last_name"*/}
                                        {/*render={({ field }) => (*/}
                                            {/*<FormItem>*/}
                                                {/*<FormLabel className="text-black">Last Name<span className="text-destructive">*</span></FormLabel>*/}
                                                {/*<FormControl>*/}
                                                    {/*<Input placeholder="Enter last name" className="rounded-none h-9" {...field} />*/}
                                                {/*</FormControl>*/}
                                                {/*<FormMessage />*/}
                                            {/*</FormItem>*/}
                                        {/*)}*/}
                                    {/*/>*/}
                                {/*</div>*/}
                                <div className="sm:col-span-1col-span-1">
                                    <FormField
                                        control={form.control}
                                        name="email"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel className="text-black">Email<span className="text-destructive">*</span></FormLabel>
                                                <FormControl>
                                                    <Input type="email" placeholder="Enter Email" className="rounded-none h-9" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                {/* <div className="sm:col-span-1 col-span-2">
                                    <FormField
                                        control={form.control}
                                        name="dob"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel className="text-black">Date of Birth<span className="text-destructive">*</span></FormLabel>
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <FormControl>
                                                            <Button
                                                                variant={"outline"}
                                                                className={cn(
                                                                    "w-full pl-3 text-left font-normal rounded-none h-9",
                                                                    !field.value && "text-muted-foreground"
                                                                )}
                                                                onClick={() => setCloseCalader(true)}
                                                            >
                                                                {field.value ? (
                                                                    format(field.value, "PPP")
                                                                ) : (
                                                                    <span>Date of birth</span>
                                                                )}
                                                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                            </Button>
                                                        </FormControl>
                                                    </PopoverTrigger>
                                                    {closeCalader &&<PopoverContent className="w-auto p-0" align="start">
                                                        <Calendar
                                                            mode="single"
                                                            captionLayout="dropdown-buttons"
                                                            selected={field.value}
                                                            onSelect={(date) => {
                                                                field.onChange(date)
                                                                setCloseCalader(false)
                                                            }}
                                                            disabled={(date) =>
                                                                date > new Date() || date < new Date("1900-01-01")
                                                            }
                                                            fromYear={1960}
                                                            toYear={moment().year()}
                                                        />
                                                    </PopoverContent>}
                                                </Popover>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div> */}
                                {/* <div className="sm:col-span-1 col-span-2">
                                    <FormField
                                        control={form.control}
                                        name="experience"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel className="text-black">Work Experience in Year<span className="text-destructive">*</span></FormLabel>
                                                <FormControl>
                                                    <Input type="text" className="rounded-none h-9" placeholder="Enter Work Experience" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div> */}
                                <div className="sm:col-span-1 col-span-2">
                                    <FormField
                                        control={form.control}
                                        name="phone"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel className="text-black">Mobile No.<span className="text-destructive">*</span></FormLabel>
                                                <FormControl>
                                                    <PhoneInput
                                                    placeholder="Enter phone number *"
                                                    defaultValue={field.value}
                                                    onClick={setValue}
                                                    defaultCountry="IN"
                                                    international
                                                    limitMaxLength={true}
                                                    maxLength="16"
                                                    countryCallingCodeEditable={false}
                                                    className="border-[#D2D6DE] rounded-none h-9 border-solid border ps-2 text-[#828282]"
                                                    {...field}
                                                    onChange={field.onChange}
                                                    rules={{ required: true }} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                {/* <div className="col-span-2">
                                    <FormField
                                        control={form.control}
                                        name="country"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel className="text-black">Country<span className="text-destructive">*</span></FormLabel>
                                                <FormControl>
                                                    <Input type="text" className="rounded-none h-9" placeholder="Enter country" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div> */}
                                <div className="col-span-2">
                                    <FormField
                                        control={form.control}
                                        name="preferences"
                                        render={({ field }) => (
                                            <FormItem className="text-black">
                                                <FormLabel className="text-left col-span-6 md:col-span-2">Class preferences<span className="text-destructive">*</span></FormLabel>
                                                <div className="col-span-6 md:col-span-4">
                                                    <FormControl>
                                                        <Select onValueChange={field.onChange} value={field.value}>
                                                            <SelectTrigger style={{ marginBottom: "0" }} className="border-[#D2D6DE] rounded-none h-9 text-[#828282]">
                                                                <SelectValue placeholder="Choose a preferences class" />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectItem value="Weekend">Weekend</SelectItem>
                                                                <SelectItem value="Weekday-Regular">Weekday-Regular</SelectItem>
                                                                <SelectItem value="Weekday-Flexi-hour">Weekday-Flexi hour</SelectItem>
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                    <FormMessage className="absolute text-[11px]" />
                                                </div>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                {/* <div className="sm:col-span-1 col-span-2">
                                    <FormField
                                        control={form.control}
                                        name="city"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel className="text-black">City<span className="text-destructive">*</span></FormLabel>
                                                <FormControl>
                                                    <Input type="text" className="rounded-none h-9" placeholder="Enter city" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div> */}
                            </div>
                            <div className="border-t py-3 px-6">
                            <DialogFooter className="gap-3">
                                    <Button type="button" onClick={()=>handleReset()} variant="secondary" className="w-full md:w-1/2 py-2 px-10 rounded-none">
                                        Reset
                                    </Button>
                                <Button type="submit" className="w-full md:w-1/2 rounded-none" disabled={loading}>{loading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin " />}Download brochure</Button>
                            </DialogFooter>
                            </div>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    )
}
